import "./styles/dashboard.scss";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import { getUsersData } from "../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const DashBoard = () => {
  const users = useSelector((state) => state.userReducer.users);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersData());
  }, []);

  return (
    <div className="dashBoardWrapper">
      <h1>DASHBOARD</h1>
      <div className="mainCardContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <div className="mainCard">
              <div className="contentContainer">
                <div className="content">
                  <p>{Object.keys(users).length}</p>
                  <p>User Registration</p>
                </div>
                <div className="icon">
                  <PersonIcon />
                </div>
              </div>
              <div className="cardFooter">more info</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DashBoard;
