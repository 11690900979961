import React, { useEffect, useState } from "react";
import "./styles/adminAllUsers.scss";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Typography, Modal } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getUsersData } from "../redux/actions/users";
import { getVehicleCompanyData } from "../redux/actions/AdminVehicleCompany";
import { getVehicleModalData } from "../redux/actions/AdminVehicleModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d9edf7 ",
    fontWeight: "700",
    padding: "15px 10px",
    "&.MuiTableCell-head": {
      minWidth: "100px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AdminAllUsers = () => {
  const users = useSelector((state) => state.userReducer.users);
  const { vehicleCompanies } = useSelector(
    (state) => state.adminVehicleCompanyReducer
  );
  const { vehicleModals } = useSelector((state) => state.adminVehicleModal);
  const [modalData, setModalData] = useState({
    open: false,
    modalType: "",
    modalData: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersData());
    dispatch(getVehicleCompanyData());
    dispatch(getVehicleModalData());
  }, []);

  const handleCloseModal = () => {
    setModalData({
      open: false,
      modalType: "",
      modalData: [],
    });
  };

  const handleOpenModal = (type, data) => {
    setModalData({
      open: true,
      modalType: type,
      modalData: data,
    });
  };

  return (
    <div className="container">
      <h1> All Users</h1>

      <div className="tableContainer">
        <TableContainer component={Paper}>
          <Table
            aria-label="customized table"
            sx={{ minWidth: 650, overflowX: "scroll" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sl.No</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Mobile</StyledTableCell>
                <StyledTableCell align="center">Address</StyledTableCell>
                <StyledTableCell align="center">
                  Vehical Details
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(users).map((user, index) => (
                <StyledTableRow key={user}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {users[user].username}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {users[user].email}
                  </StyledTableCell>{" "}
                  <StyledTableCell align="center">
                    {users[user].mobile}
                  </StyledTableCell>{" "}
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() =>
                        handleOpenModal("address", users[user].address)
                      }
                    >
                      See Address
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() =>
                        handleOpenModal("Vehicals", users[user].vehicle)
                      }
                    >
                      See Details
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {modalData.open && (
        <Modal open={modalData.open} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "white",
              padding: 4,
              borderRadius: "5px",
              minWidth: 500,
              maxHeight: "95vh",
              overflowY: "auto",
            }}
          >
            <Typography
              sx={{ mb: 2, textTransform: "capitalize" }}
              variant="h6"
            >
              {modalData.modalType}
            </Typography>
            <div>
              {modalData.modalType === "address" && (
                <TableContainer component={Paper}>
                  <Table
                    aria-label="customized table"
                    sx={{ minWidth: 650, overflowX: "scroll" }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Address Primary
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Adress{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          PinCode
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalData.modalData &&
                        modalData.modalData.map((data) => (
                          <StyledTableRow key={data.id}>
                            <StyledTableCell align="center">
                              {data.address_priamry}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data.address1}
                            </StyledTableCell>{" "}
                            <StyledTableCell align="center">
                              {data.pincode}
                            </StyledTableCell>{" "}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {modalData.modalType === "Vehicals" && (
                <TableContainer component={Paper}>
                  <Table
                    aria-label="customized table"
                    sx={{ minWidth: 650, overflowX: "scroll" }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          vehicle brand
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          vehicle model{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          vehicle number
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          vehicle Type
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalData.modalData &&
                        modalData.modalData.map((data) => (
                          <StyledTableRow key={data.id}>
                            <StyledTableCell align="center">
                              {
                                vehicleCompanies.filter(
                                  (item) => item.id === data.vheicle_brand
                                )[0].company_name
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {
                                vehicleModals.filter(
                                  (item) => item.id === data.vheicle_model
                                )[0].vehicle_modal
                              }
                            </StyledTableCell>{" "}
                            <StyledTableCell align="center">
                              {data.vheicle_number}
                            </StyledTableCell>{" "}
                            <StyledTableCell align="center">
                              {data.vheicle_type}
                            </StyledTableCell>{" "}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AdminAllUsers;
