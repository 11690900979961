export const servicePlanheadCells = [
  {
    id: "sl No",
    numeric: false,
    disablePadding: true,
    label: "Sl No",
    isSortable: false,
  },
  {
    id: "field_name",
    numeric: false,
    disablePadding: false,
    label: "Service Plan",
    isSortable: true,
  },
  {
    id: "add_service",
    numeric: false,
    disablePadding: false,
    label: "Add Serices",
    isSortable: false,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Create At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Updated At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    isSortable: false,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    isSortable: false,
  },
];

export const serviceListheadCells = [
  {
    id: "sl No",
    numeric: false,
    disablePadding: true,
    label: "Sl No",
    isSortable: false,
  },
  {
    id: "service_name",
    numeric: false,
    disablePadding: false,
    label: "Service Name",
    isSortable: true,
  },
  {
    id: "company name",
    numeric: false,
    disablePadding: true,
    label: "company name",
    isSortable: false,
  },
  {
    id: "vehicle_modal",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Modal",
    isSortable: false,
  },
  {
    id: "fuel_type",
    numeric: false,
    disablePadding: true,
    label: "Fuel Type",
    isSortable: false,
  },
  {
    id: "vehical_type",
    numeric: false,
    disablePadding: true,
    label: "Vehical Type",
    isSortable: false,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    isSortable: true,
  },
  {
    id: "discount",
    numeric: true,
    disablePadding: false,
    label: "DisCount",
    isSortable: true,
    isDateSort: false,
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
    isSortable: true,
    isDateSort: false,
  },
  {
    id: "advance_price",
    numeric: true,
    disablePadding: false,
    label: "Advance Price",
    isSortable: true,
    isDateSort: false,
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    isSortable: false,
  },
  {
    id: "deactive",
    numeric: false,
    disablePadding: false,
    label: "State",
    isSortable: false,
  },
];


export const vehicleCompanyheadCells = [
  {
    id: "sl No",
    numeric: false,
    disablePadding: true,
    label: "Sl No",
    isSortable: false,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    isSortable: true,
  },

  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Create At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Updated At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    isSortable: false,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    isSortable: false,
  },
];

export const vehicleModalheadCells = [
  {
    id: "sl No",
    numeric: false,
    disablePadding: true,
    label: "Sl No",
    isSortable: false,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    isSortable: true,
  },

  {
    id: "vehicle_modal",
    numeric: false,
    disablePadding: false,
    label: "Modal Name",
    isSortable: true,
  },
  {
    id: "variant",
    numeric: false,
    disablePadding: false,
    label: " Variant",
    isSortable: true,
  },
  {
    id: "fuel_type",
    numeric: false,
    disablePadding: false,
    label: "Fuel Type",
    isSortable: true,
  },
  {
    id: "vehicle_type",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Type",
    isSortable: true,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Create At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Updated At",
    isSortable: true,
    isDateSort: true,
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    isSortable: false,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    isSortable: false,
  },
];